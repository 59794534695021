// src/pages/Leads/Leads.js

import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import { UserContext } from '../../UserContext';
import { getAuth } from 'firebase/auth'; // Import getAuth to retrieve idToken
import './Leads.css'; // Import the CSS for transitions
import PageLoader from '../../components/PageLoader'; 

// Components
import FilterSection from '../components/FilterSection';
import LeadCard from './components/LeadCard';
import SkeletonCard from './components/SkeletonCard';
import BackToTop from './components/BackToTop'; // Import BackToTop

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

// Main Theme
const customTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
    },
    primary: {
      main: '#1F1F1F',
    },
  },
});

export default function Leads({ setIsFooterVisible, isPremium = false }) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useContext(UserContext); // Consume only user from UserContext

  // Parse filters from URL query parameters
  const query = new URLSearchParams(location.search);
  const initialFilters = {
    searchText: query.get('searchText') || '',
    selectedCategory: query.get('selectedCategory')
      ? query.get('selectedCategory').split(',').map(Number)
      : [0],
    dateRange: [
      query.get('startDate') ? new Date(query.get('startDate')) : null,
      query.get('endDate') ? new Date(query.get('endDate')) : null,
    ],
  };

  const [filters, setFilters] = useState(initialFilters);

  // Determine if a search has been performed based on initial filters
  const initialHasSearched = initialFilters.searchText.trim() !== '' ||
    initialFilters.selectedCategory.length > 0 ||
    initialFilters.dateRange.some((date) => date !== null);

  const [hasSearched, setHasSearched] = useState(initialHasSearched);

  // Initialize isLoading based on hasSearched
  const [isLoading, setIsLoading] = useState(initialHasSearched);

  const [leads, setLeads] = useState([]);
  const [hasMore, setHasMore] = useState(false); // Initialize to false
  const [error, setError] = useState(null);

  // State for pagination
  const [page, setPage] = useState(0);

  // State for FilterSection Dialog
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  // Function to fetch idToken
  const getIdToken = async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      try {
        return await auth.currentUser.getIdToken(true);
      } catch (error) {
        console.error('Error fetching idToken:', error);
        return null;
      }
    }
    return null;
  };

  // Function to fetch data from the backend
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const startDate = filters.dateRange[0]
        ? filters.dateRange[0].toISOString().split('T')[0]
        : '';
      const endDate = filters.dateRange[1]
        ? filters.dateRange[1].toISOString().split('T')[0]
        : '';

      // Determine pageSize based on whether it's the initial load or a scroll fetch
      const currentPageSize = page === 0 ? 20 : 15;

      // Use isPremium to choose the correct API endpoint
      const apiEndpoint = isPremium ? 'getPremiumLeads' : 'getLeads';

      const url = `https://us-central1-nextgen-react-app-439601.cloudfunctions.net/${apiEndpoint}?page=${page}&pageSize=${currentPageSize}&searchText=${encodeURIComponent(
        filters.searchText
      )}&selectedCategory=${encodeURIComponent(
        filters.selectedCategory.join(',')
      )}&startDate=${startDate}&endDate=${endDate}`;

      const fetchOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (isPremium && user) {
        const idToken = await getIdToken();
        if (idToken) {
          fetchOptions.headers['Authorization'] = `Bearer ${idToken}`;
        } else {
          throw new Error('Failed to retrieve authentication token.');
        }
      }

      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Unauthorized access. Please sign in again.');
        } else if (response.status === 403) {
          throw new Error('Forbidden: Premium access required.');
        } else if (response.status === 429) {
          throw new Error('Too many requests. Please try again later.');
        } else if (response.status >= 500 && response.status < 600) {
          throw new Error('Server error. Please try again later.');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Invalid data format received from API.');
      }

      if (data.length < currentPageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setLeads((prevLeads) => (page === 0 ? data : [...prevLeads, ...data]));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Use actual error message or map specific errors
      if (error.message.includes('Too many requests')) {
        setError('Too many requests. Please try again in 5 minutes.');
      } else if (error.message.includes('Unauthorized')) {
        setError('Session expired. Please sign in again.');
        navigate('/signin'); // Optionally, redirect to sign-in page
      } else if (error.message.includes('Forbidden')) {
        setError('You do not have access to premium leads.');
      } else if (error.message.includes('Server error')) {
        setError('Server error. Please try again later.');
      } else if (error.message.includes('Invalid data format')) {
        setError('Received unexpected data from the server.');
      } else {
        setError(error.message);
      }
      setIsLoading(false);
    }
  };

  // Load more data when user scrolls down
  const fetchMoreData = () => {
    if (isLoading || !hasMore) return;
    setPage((prevPage) => prevPage + 1);
  };

  // Fetch data when filters or page changes
  useEffect(() => {
    if (!hasSearched) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page]);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();

    if (filters.searchText) {
      params.append('searchText', filters.searchText);
    }

    if (filters.selectedCategory.length > 0) {
      params.append('selectedCategory', filters.selectedCategory.join(','));
    }

    if (filters.dateRange[0]) {
      params.append('startDate', filters.dateRange[0].toISOString().split('T')[0]);
    }
    if (filters.dateRange[1]) {
      params.append('endDate', filters.dateRange[1].toISOString().split('T')[0]);
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  // Handle search when user clicks the Search button
  const handleSearch = (newFilters) => {
    setFilters(newFilters);
    setHasSearched(true); // Indicate that a search has been performed
    setPage(0);
    setHasMore(true); // Enable infinite scroll
    setLeads([]);
    setIsLoading(true);
    handleFilterClose(); // Close the filter popup
  };

  // Hide the footer when Leads component mounts
  useEffect(() => {
    setIsFooterVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show footer when all data is loaded
  useEffect(() => {
    if (!isLoading && !hasMore) {
      setIsFooterVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMore]);

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', paddingBottom: 5 }}>

        {/* Full-Page Loader */}
        <PageLoader open={isLoading} />

        {/* Upgrade to Premium Message */}
        {!isPremium && (
          <Box sx={{ 
            backgroundColor: '#F5F5F5', 
            padding: 2, 
            borderRadius: 1, 
            textAlign: 'center', 
            border: '1px solid #E0E0E0',
            mt: 2 
          }}>
            <Typography variant="body1" color="textPrimary" sx={{ fontWeight: 500, mb: 1 }}>
              Access Fresh, High-Quality Leads!
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              You're exploring <strong>older</strong> leads. Unlock premium access to the freshest leads and stay ahead of the curve!
            </Typography>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => navigate('/premium')}
              sx={{
                textTransform: 'none',
                borderColor: '#1F1F1F',
                '&:hover': { 
                  borderColor: '#333333',
                },
              }}
            >
              Get Premium 
            </Button>
          </Box>
        )}

        {/* Search Button at Top Right */}
        {hasSearched && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 4, pr: 8, gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<SearchRoundedIcon />}
              onClick={handleFilterOpen}
              sx={{
                textTransform: 'none',
                backgroundColor: 'white',
                color: 'primary.main', // Black text
                boxShadow: 'none', // Remove default shadow
                border: '1px solid #CCCCCC', // Light grey border for subtlety
                transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transitions
                '&:hover': {
                  backgroundColor: '#f5f5f5', // Light grey background on hover
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow on hover
                },
              }}
            >
              Search
            </Button>

            {/* Alerts Button */}
            <Button
              variant="contained"
              startIcon={<NotificationsNoneOutlinedIcon />} // Added Notifications Icon
              onClick={() => navigate('/alerts')}
              sx={{
                textTransform: 'none',
                backgroundColor: 'white',
                color: 'primary.main', // Purple text
                boxShadow: 'none', // Remove default shadow
                border: '1px solid #CCCCCC', // Light grey border for consistency
                transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                '&:hover': {
                  backgroundColor: '#FFF3E0', // Light purple background on hover
                  borderColor: '#FFA726', // Orange border on hover for better visibility
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow on hover
                },
                '& .MuiButton-startIcon': {
                  color: 'secondary.main', // Ensure the bell icon keeps its secondary color
                },
              }}
            >
              Alerts
            </Button>
          </Box>
        )}

        {/* Filter Section Popup */}
        <FilterSection
          open={isFilterOpen}
          onClose={handleFilterClose}
          handleSearch={handleSearch}
        />

        {/* Error Message */}
        {error && (
          <Typography variant="h6" color="error" gutterBottom align="center">
            {error}
          </Typography>
        )}

        {/* Loading Skeletons During Initial Load */}
        {isLoading && leads.length === 0 ? (
          <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              {[...Array(9)].map((_, idx) => (
                <Grid item xs={12} sm={6} md={4} key={`skeleton-${idx}`}>
                  <SkeletonCard />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          // Conditionally Render Infinite Scroll Only After a Search
          hasSearched && (
            <>
              <InfiniteScroll
                dataLength={leads.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <Box sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                      {[...Array(3)].map((_, idx) => (
                        <Grid item xs={12} sm={6} md={4} key={`skeleton-${idx}`}>
                          <SkeletonCard />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                }
                style={{ overflow: 'hidden' }}
              >
                {/* TransitionGroup for Fade-In Effects */}
                <Grid container spacing={2} sx={{ padding: 2 }}>
                  <TransitionGroup component={null}>
                    {leads.map((lead, index) => (
                      <CSSTransition key={lead.id || index} timeout={500} classNames="fade">
                        <Grid item xs={12} sm={6} md={4}>
                          <LeadCard lead={lead} />
                        </Grid>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </Grid>
              </InfiniteScroll>

              {/* Display No Results Message Outside InfiniteScroll */}
              {leads.length === 0 && !isLoading && hasSearched && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" align="center">
                    No results found for your search criteria.
                  </Typography>
                </Box>
              )}
            </>
          )
        )}

        {/* Back to Top Button */}
        <BackToTop />
      </Box>
    </ThemeProvider>
  );
}
