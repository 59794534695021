// src/pages/Home/Home.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Fade,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FilterSection from '../components/FilterSection';
import { useNavigate } from 'react-router-dom';

import '@fontsource/barlow/800.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/public-sans/300.css';

const theme = createTheme({
  typography: {
    fontFamily:
      '"Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h3: {
      fontWeight: 800,
      fontSize: '72px',
      lineHeight: '90px',
      color: 'rgba(128, 128, 128, 0.4)',
      textAlign: 'center',
      WebkitFontSmoothing: 'antialiased',
      '@media (max-width:600px)': {
        fontSize: '48px',
        lineHeight: '60px',
      },
    },
    h4: {
      fontWeight: 800,
      fontSize: '72px',
      lineHeight: '90px',
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      WebkitFontSmoothing: 'antialiased',
      '@media (max-width:600px)': {
        fontSize: '48px',
        lineHeight: '60px',
      },
    },
    body1: {
      fontSize: '1.125rem',
      color: 'rgba(28, 37, 46, 0.6)',
      textAlign: 'center',
      maxWidth: '600px',
      margin: '0 auto',
      '@media (max-width:600px)': {
        fontSize: '0.8rem', // Smaller font for mobile
      },
    },
    button: {
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  palette: {
    background: {
      default: '#f9f9f9',
    },
    text: {
      primary: '#333333',
    },
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#f5f5f5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'transform 0.2s, background-color 0.2s, box-shadow 0.2s',
        },
      },
    },
  },
});

const Home = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const isFilterOpen = Boolean(filterAnchorEl);

  const handleSearch = (filters) => {
    const params = new URLSearchParams();
    if (filters.searchText) {
      params.append('searchText', filters.searchText);
    }
    if (filters.selectedCategory.length > 0) {
      params.append('selectedCategory', filters.selectedCategory.join(','));
    }
    if (filters.dateRange[0]) {
      params.append('startDate', filters.dateRange[0].toISOString().split('T')[0]);
    }
    if (filters.dateRange[1]) {
      params.append('endDate', filters.dateRange[1].toISOString().split('T')[0]);
    }
    navigate(`/leads?${params.toString()}`);
  };

  // --------------------------------------
  // Subscription Pop-up State + Handler
  // --------------------------------------
  const [phoneNumber, setPhoneNumber] = useState('');
  const [consentChecked, setConsentChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCTA, setShowCTA] = useState(true);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  // Helper to validate exactly 10 digits
  const isValidPhone = (phone) => /^[0-9]{10}$/.test(phone);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate 10-digit phone number
    if (!isValidPhone(phoneNumber)) {
      alert('Please enter a valid 10-digit phone number with no spaces or symbols.');
      return;
    }

    // Simulate an async request
    setIsSubmitting(true);

    // We'll just wait 1 second, then pretend success
    setTimeout(() => {
      // Stop spinner, show success message
      setIsSubmitting(false);
      setSubscriptionSuccess(true);

      // Reset form
      setPhoneNumber('');
      setConsentChecked(false);
    }, 1000);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          pt: '10vh',
          backgroundColor: 'background.default',
          backgroundImage: 'url("/assets/pattern.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={4}
          sx={{ width: '100%', maxWidth: '100%' }}
        >
          {/* Top Section */}
          <Grid item>
            <Fade in={true} timeout={1000}>
              <Typography variant="h3">Powered by AI</Typography>
            </Fade>
            <Fade in={true} timeout={1000}>
              <Typography variant="h4" sx={{ display: 'inline', mt: 1 }}>
                access quality{' '}
                <span
                  style={{
                    background:
                      'linear-gradient(90deg, #32CD32, #20B2AA, #66CDAA, #FFD700, #FFA500, #32CD32)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    display: 'inline-block',
                    animation: 'subtleGreenGoldFlow 8s ease-in-out infinite',
                    backgroundSize: '300% 100%',
                  }}
                >
                  leads
                </span>
              </Typography>
            </Fade>
          </Grid>

          {/* Middle Section */}
          <Grid item>
            <Fade in={true} timeout={1200}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"Public Sans Thin", sans-serif',
                  color: 'rgb(28, 37, 46)',
                  lineHeight: 2,
                }}
              >
                Discover a tool crafted to make finding leads easier.
                <br />
                Simplify and streamline your search process effortlessly.
              </Typography>
            </Fade>
          </Grid>

          {/* Bottom Section: Search Button */}
          <Grid item>
            <Fade in={true} timeout={1500}>
              <Button
                variant="contained"
                startIcon={<SearchRoundedIcon />}
                sx={{
                  width: { xs: '200px', sm: '250px', md: '280px' },
                  height: '45px',
                  borderRadius: '25px',
                  bgcolor: 'white',
                  color: 'primary.main',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    bgcolor: 'secondary.main',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    transform: 'scale(1.05)',
                  },
                  '&:active': {
                    transform: 'scale(0.98)',
                  },
                }}
                onClick={handleFilterClick}
                aria-label="Search Leads"
              >
                Search Leads
              </Button>
            </Fade>
          </Grid>

          {/* Filter Section */}
          <FilterSection
            anchorEl={filterAnchorEl}
            open={isFilterOpen}
            onClose={handleFilterClose}
            handleSearch={(filters) => {
              handleSearch(filters);
              handleFilterClose();
            }}
          />
        </Grid>

        {/** =========================================
             CHAT-LIKE CTA in bottom-right corner
         ========================================= */}
        {showCTA && (
          <Box
            sx={{
              position: 'fixed',
              bottom: '30px',
              right: '30px',
              width: 300,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
              backgroundColor: '#fff',
              overflow: 'hidden',
              zIndex: 999,
              border: '1px solid #eee',
            }}
          >
            {/* Header row with title + close button */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 1,
                px: 2,
                backgroundColor: '#f0f0f0',
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#333' }}>
                SMS Updates
              </Typography>
              <IconButton
                size="small"
                onClick={() => setShowCTA(false)}
                sx={{ color: '#333' }}
                aria-label="close"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>

            {/* The subscription form or success screen */}
            {!subscriptionSuccess ? (
              /* FORM STATE */
              <Box
                component="form"
                onSubmit={handleFormSubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1.5,
                  p: 2,
                  textAlign: 'center',
                }}
              >
                <TextField
                  placeholder="Enter 10-digit Phone #"
                  variant="outlined"
                  type="tel"
                  required
                  size="small"
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  // Basic HTML pattern for exactly 10 digits:
                  inputProps={{
                    pattern: '^[0-9]{10}$',
                    title: 'Please enter exactly 10 digits (no spaces or symbols).',
                  }}
                />

                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      checked={consentChecked}
                      onChange={(e) => setConsentChecked(e.target.checked)}
                      required
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                      I consent to receive promotional texts
                    </Typography>
                  }
                  sx={{ mx: 'auto' }}
                />

                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '0.7rem',
                    color: '#555',
                    lineHeight: 1.2,
                  }}
                >
                  By subscribing, you consent to recurring promotional texts from
                  SmarterLeadFinder.com. Msg &amp; data rates may apply.
                  Frequency varies. Reply STOP to cancel. Consent is not a
                  condition of purchase.{' '}
                  <a href="/terms" target="_blank" rel="noopener noreferrer">
                    Terms
                  </a>{' '}
                  /{' '}
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy
                  </a>.
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{ fontSize: '0.85rem' }}
                >
                  {isSubmitting ? <CircularProgress size={16} /> : 'Subscribe'}
                </Button>
              </Box>
            ) : (
              /* SUCCESS STATE */
              <Box
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  textAlign: 'center',
                }}
              >
                <CheckCircleOutlineIcon
                  sx={{ color: 'green', fontSize: 40 }}
                />
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Subscription Successful!
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                  You’re now set to receive promotional texts.<br />
                  Feel free to close this window or keep browsing.
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {/* Keyframe for the "leads" text animation */}
        <style jsx="true">{`
          @keyframes subtleGreenGoldFlow {
            0% {
              background-position: 0% 0%;
            }
            50% {
              background-position: 100% 0%;
            }
            100% {
              background-position: 0% 0%;
            }
          }
        `}</style>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
