// src/routes/PremiumRoute.js
import React, { useContext } from 'react';
import { UserContext } from '../UserContext';
import BuyPremium from '../pages/BuyPremium/BuyPremium';
import { CircularProgress, Box } from '@mui/material';

const PremiumRoute = ({ children }) => {
  const { user, isPremium } = useContext(UserContext);

  // While user data is being loaded
  if (user === undefined) {
    return (
      <Box
        sx={{
          minHeight: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If user has premium, show whatever's wrapped in <PremiumRoute>...</PremiumRoute>
  if (user && isPremium) {
    return children;
  }

  // Otherwise, show the buy/upgrade page
  return <BuyPremium />;
};

export default PremiumRoute;
