// src/pages/Alerts/Alerts.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  TextField,
  Switch,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';

// Snackbar Alert Component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alerts = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const db = getFirestore();

  // State Variables
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [alertEmail, setAlertEmail] = useState(user?.email || '');
  const [alertsEnabled, setAlertsEnabled] = useState(true);
  const [initialCategories, setInitialCategories] = useState([]);
  const [initialAlertsEnabled, setInitialAlertsEnabled] = useState(true);
  const [initialAlertEmail, setInitialAlertEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const availableCategories = [
    { label: 'All', name: 'All' },
    { label: '12', name: 'Carpenter' },
    { label: '3', name: 'Electrician' },
    { label: '9', name: 'General Contractor' },
    { label: '10', name: 'Gutter Cleaning' },
    { label: '1', name: 'Handyman' },
    { label: '11', name: 'Housekeeper' },
    { label: '5', name: 'HVAC Technician' },
    { label: '4', name: 'Landscaper' },
    { label: '7', name: 'Painter' },
    { label: '8', name: 'Pest Control' },
    { label: '2', name: 'Plumber' },
    { label: '6', name: 'Roofer' },
  ];

  // Email Validation Function
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // Fetch user's current alert settings from Firestore
  useEffect(() => {
    const loadUserAlerts = async () => {
      if (!user || !user.uid) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setLoading(false);
        return;
      }

      try {
        const alertsDocRef = doc(db, 'alerts', user.uid);
        const alertsDoc = await getDoc(alertsDocRef);

        if (alertsDoc.exists()) {
          const alertsData = alertsDoc.data();
          const userCategoriesData = alertsData.alert_categories || [];
          const userCategories = userCategoriesData.map((cat) => cat.label);
          const userAlertEmail = alertsData.alert_email || user.email;
          const userAlertsEnabled =
            alertsData.alerts_enabled !== undefined ? alertsData.alerts_enabled : true;

          setSelectedCategories(userCategories);
          setInitialCategories(userCategories);
          setAlertEmail(userAlertEmail);
          setInitialAlertEmail(userAlertEmail);
          setAlertsEnabled(userAlertsEnabled);
          setInitialAlertsEnabled(userAlertsEnabled);
        } else {
          // Initialize the document with the user's email, empty categories, and alerts enabled
          await setDoc(alertsDocRef, {
            email: user.email,
            alert_categories: [],
            alert_email: user.email,
            alerts_enabled: true,
          });
          setSelectedCategories([]);
          setInitialCategories([]);
          setAlertEmail(user.email);
          setInitialAlertEmail(user.email);
          setAlertsEnabled(true);
          setInitialAlertsEnabled(true);
        }
      } catch (error) {
        setError('Failed to load alert settings. Please try again later.');
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setLoading(false);
      }
    };

    loadUserAlerts();
  }, [user, db]);

  // Handle Checkbox Changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'All') {
      if (checked) {
        setSelectedCategories(['All']);
      } else {
        setSelectedCategories([]);
      }
    } else {
      if (selectedCategories.includes('All')) return;

      if (checked) {
        setSelectedCategories((prev) => [...prev, name]);
      } else {
        setSelectedCategories((prev) => prev.filter((category) => category !== name));
      }
    }
  };

  // Handle Toggle Changes
  const handleToggleChange = (event) => {
    setAlertsEnabled(event.target.checked);
  };

  // Handle Save Function
  const handleSave = async () => {
    if (!user || !user.uid) {
      setError('You must be logged in to save alert settings.');
      return;
    }

    // Validate Email
    if (!isValidEmail(alertEmail)) {
      setError('Please enter a valid email address.');
      setSuccess(null);
      return;
    }

    const hasCategoriesChanged =
      JSON.stringify(selectedCategories) !== JSON.stringify(initialCategories);
    const hasToggleChanged = alertsEnabled !== initialAlertsEnabled;
    const hasEmailChanged = alertEmail !== initialAlertEmail;

    if (!hasCategoriesChanged && !hasToggleChanged && !hasEmailChanged) {
      setSuccess('No changes to save.');
      setError(null);
      return;
    }

    try {
      const alertsDocRef = doc(db, 'alerts', user.uid);
      const updateData = {};

      if (hasCategoriesChanged) {
        // Store only the label, no name
        updateData.alert_categories = selectedCategories.map((label) => {
          return { label: label };
        });
      }

      if (hasToggleChanged) {
        updateData.alerts_enabled = alertsEnabled;
      }

      if (hasEmailChanged) {
        updateData.alert_email = alertEmail;
      }

      await updateDoc(alertsDocRef, updateData);
      setInitialCategories(selectedCategories);
      setInitialAlertsEnabled(alertsEnabled);
      setInitialAlertEmail(alertEmail);
      setSuccess('Alert settings saved successfully.');
      setError(null);
    } catch (error) {
      setError('Failed to save alert settings. Please try again.');
      setSuccess(null);
    }
  };

  // Redirect Unauthenticated Users to /signin
  if (loading) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user || !user.uid) {
    navigate('/signin', { state: { from: '/alerts' } });
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: 'background.default',
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          maxWidth: 800,
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Set Up Alerts
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Choose the categories for which you’d like to receive alerts.
        </Typography>

        {/* Email Input */}
        <TextField
          label="Alert Email"
          variant="outlined"
          fullWidth
          value={alertEmail}
          onChange={(e) => setAlertEmail(e.target.value)}
          sx={{ mb: 3 }}
        />

        {/* Alerts Enabled Switch */}
        <FormControlLabel
          control={
            <Switch
              checked={alertsEnabled}
              onChange={handleToggleChange}
              color="primary"
            />
          }
          label="Enable Alerts"
          sx={{ mb: 3 }}
        />

        {/* Alert Categories */}
        <Grid container spacing={2}>
          {availableCategories.map((category) => (
            <Grid
              item
              xs={6}
              sm={4}
              key={category.label}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                minHeight: 40,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={category.label}
                    checked={selectedCategories.includes(category.label)}
                    onChange={handleCheckboxChange}
                    sx={{
                      color: '#757575',
                      '&.Mui-checked': {
                        color: '#424242',
                      },
                    }}
                    disabled={
                      selectedCategories.includes('All') && category.label !== 'All'
                    }
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: 'left',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    {category.name}
                  </Typography>
                }
                sx={{
                  marginLeft: 0,
                }}
              />
            </Grid>
          ))}
        </Grid>

        {/* Save Button */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              textTransform: 'none',
              backgroundColor: '#1F1F1F',
              '&:hover': { backgroundColor: '#333333' },
            }}
          >
            Save Changes
          </Button>
        </Box>

        {/* Success and Error Snackbars */}
        <Snackbar
          open={success !== null}
          autoHideDuration={6000}
          onClose={() => setSuccess(null)}
        >
          <Alert
            onClose={() => setSuccess(null)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {success}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error !== null}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default Alerts;
